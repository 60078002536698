import { Category, ListWrapper } from "./SliderRow.styles";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { RowAsset } from "./RowAsset";
import { assetPagination, initializePaginationForShelf } from "./shelfUtil";
import { useFeature } from "../../../hooks/useFeature";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Mousewheel } from "swiper/modules";
import next from "../../../assets/images/arrow-right-white.svg";
import { Asset, AssetList } from "../../adminFeatures/assets/assetTypes";
import { flixProviderSignal, useFlix } from "../../flix/useFlix";
import { useDrag } from '@use-gesture/react'
import { userIsAmdinSginal } from "../../user/userSignals";
import { useHistory } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type ListProps = {
  value: number;
  shelf: AssetList;
  index: number;
  provider: number;
};

const breakpoints = {
  2250: {
    slidesPerView: 6,
    spaceBetween: 15,
    initialSlide: 0,
  },
  1260: {
    slidesPerView: 6,
    spaceBetween: 15,
    initialSlide: 0,
  },

  520: {
    slidesPerView: 6,
    spaceBetween: 10,
    initialSlide: 0,
  },
  0: {
    slidesPerView: 6,
    spaceBetween: 5,
    initialSlide: 0,
  },
};

const SliderRow = React.memo<ListProps>((props) => {
  const { value, index, shelf } = props;
  const history = useHistory();
  const { ref, focusKey } = useFocusable({ trackChildren: true });
  const [prevArrowVisible, setPrevArrowVisible] = useState(false);
  const [nextArrowVisible, setNextArrowVisible] = useState(false);
  const { useFetchAndAddAssets } = useFlix();
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragTimeoutRef = useRef<any>(null);

  // Initialize the custom hook
  const mutate = useFetchAndAddAssets(shelf.asset_list_type_id);
  const { assetClick } = useFeature();
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const bind = useDrag(
    ({ dragging }) => {
      if (dragging) {
        // User is actively dragging
        setIsDragging(true);
  
        // Clear any existing timeout when dragging starts
        if (dragTimeoutRef.current) {
          clearTimeout(dragTimeoutRef.current);
          dragTimeoutRef.current = null;
        }
      } else {
        // Dragging has ended
        if (dragTimeoutRef.current) {
          clearTimeout(dragTimeoutRef.current);
        }
        // Keep isDragging true for 1 second after drag ends
        dragTimeoutRef.current = setTimeout(() => {
          setIsDragging(false);
          dragTimeoutRef.current = null;
        }, 1000);
      }
    },
    {
      pointerEvents: true,
      threshold: 10,
      filterTaps: true,
      axis: 'x',
    }
  );

  const onEnterPress = useCallback((focused: any) => {
    assetClick(false, focused.value, focused.film);
  }, []);

  const preventHorizontalScroll = useCallback((e: WheelEvent) => {
    if (e.deltaX !== 0) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (!sliderElement) return;

    // Add event listener to the slider element
    sliderElement.addEventListener("wheel", preventHorizontalScroll, {
      passive: false,
    });

    return () => {
      // Clean up the event listener
      sliderElement.removeEventListener("wheel", preventHorizontalScroll);
    };
  }, [preventHorizontalScroll]);

  const handleButtonVisibility = useCallback(() => {
    setPrevArrowVisible(!swiperInstance.isBeginning);
    setNextArrowVisible(!swiperInstance.isEnd);
  }, [swiperInstance]);

  const handleSlide = useCallback(
    (direction: string) => {
      if (!swiperInstance) return;

      const slidesPerView = swiperInstance.params.slidesPerView as number;
      let slideToIndex = swiperInstance.activeIndex;

      if (direction === "next") {
        slideToIndex += slidesPerView;
        // Ensure we don't exceed the total number of slides
        if (slideToIndex >= swiperInstance.slides.length) {
          slideToIndex = swiperInstance.slides.length - 1;
        }
      } else {
        slideToIndex -= slidesPerView;
        // Ensure we don't go below zero
        if (slideToIndex < 0) {
          slideToIndex = 0;
        }
      }
      handleButtonVisibility();
      swiperInstance.slideTo(slideToIndex);
      if (swiperInstance.slides.length < shelf.asset_count) {
        mutate();
      }
    },
    [swiperInstance]
  );

  const handleScrollReachEnd = useCallback(() => {
    const isEnd = assetPagination.value[shelf.asset_list_type_id]?.isEnd.value;
    if (!isEnd) {
      mutate();
    }
  }, []);

  const handleCategoryClick = useCallback(() => {
    if (!userIsAmdinSginal.value) return;
    history.push(`/admin-manage-shelf/${shelf.asset_list_type_id}`);
  }, []);

  const updateSwiperConfiguration = useCallback(() => {
    if (!swiperInstance) return;

    const isBeginning = swiperInstance.isBeginning;
    // const activeIndex = swiperInstance.activeIndex;
    handleButtonVisibility();

    const opacityActiveIndex = swiperInstance.activeIndex + 2;

    swiperInstance.slides.forEach(
      (slide: { style: { opacity: string } }, index: number) => {
        // Default opacity for non-visible slides
        slide.style.opacity = "0.5";
        // Determine the visible range of slides
        let rangeStart, rangeEnd;

        if (isBeginning) {
          rangeStart = 0;
          rangeEnd = 4; // Showing the first 5 slides fully
        } else if (swiperInstance.isEnd) {
          rangeStart = opacityActiveIndex - 2;
          rangeEnd = opacityActiveIndex + 2;
        } else {
          rangeStart = opacityActiveIndex - 2; // Default range for middle slides
          rangeEnd = opacityActiveIndex + 2;
        }

        // Make slides within the visible range fully opaque
        if (index >= rangeStart && index <= rangeEnd) {
          slide.style.opacity = "1";
        }
      }
    );
  }, [swiperInstance]);

  useEffect(() => {
    initializePaginationForShelf(shelf.asset_list_type_id, 0);
  }, [shelf.asset_list_type_id, flixProviderSignal.value]);

  useEffect(() => {
    if (swiperInstance && shelf?.assets?.length) {
      updateSwiperConfiguration();
      swiperInstance.on("slideChange", updateSwiperConfiguration);
    }
    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateSwiperConfiguration);
      }
    };
  }, [shelf?.assets?.length, swiperInstance, updateSwiperConfiguration]);

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <ListWrapper ref={ref}>
          <Category
            isAdmin={userIsAmdinSginal.value}
            onClick={handleCategoryClick}
          >
            {shelf.name}
          </Category>
          <div ref={sliderRef}>
            <Swiper
              slidesPerView={5}
              slidesPerGroup={5}
              initialSlide={4}
              onSwiper={setSwiperInstance}
              onSlideChange={updateSwiperConfiguration}
              watchSlidesProgress={true}
              centeredSlides={false}
              breakpoints={breakpoints}
              modules={[Navigation, FreeMode, Mousewheel]}
              onReachEnd={() => {
                handleScrollReachEnd();
              }}
              preventInteractionOnTransition={true}
              touchReleaseOnEdges={false}
              simulateTouch={true}
              mousewheel={{
                forceToAxis: true,
                invert: false,
              }}
              draggable={true}
              // onTouchStart={() => mutate()}
              allowTouchMove={true}
              freeMode={{
                enabled: true,
                // momentum: true,
              }}
              navigation={{
                nextEl: `.next-${index}`,
                prevEl: `.prev-${index}`,
              }}
              id="home-swiper"
            >
              <div
                onClick={() => handleSlide("prev")}
                className={`prev prev-${index} ${
                  prevArrowVisible ? "" : "hidden"
                }`}
              >
                <img alt="prev" src={next} />
              </div>
              <div
                onClick={() => handleSlide("next")}
                className={`next next-${index} ${
                  nextArrowVisible ? "" : "hidden"
                }`}
              >
                <img alt="next" src={next} />
              </div>
              {shelf.assets?.map((asset: Asset, i: number) => (
                <SwiperSlide
                  {...bind()}
                  data-swiper-slide-index={i}
                  key={`${i}-${shelf.name}`}
                  className={
                    i === 0
                      ? "first-slide-margin"
                      : i === shelf.assets?.length - 1
                      ? "last-slide-margin"
                      : ""
                  }
                >
                  <RowAsset
                    isDragging={isDragging}
                    handleSlide={handleSlide}
                    swiper={swiperInstance}
                    key={i + asset.display_name + "slider"}
                    asset={asset}
                    index={index}
                    assetIndex={i}
                    onEnterPress={onEnterPress}
                    value={value}
                    parentRef={ref}
                    rowTitle={shelf.name}
                  />
                </SwiperSlide>
              ))}
              <SwiperSlide className="empty-slide"></SwiperSlide>
            </Swiper>
          </div>
        </ListWrapper>
      </FocusContext.Provider>
    </>
  );
});

export { SliderRow };
