import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { CustomTooltip } from "./HeaderTooltip.styled";
import { GridWrapper, KeyPoppupWrapper } from "./PlatformKey.styles";

const headerKeys = [
  {
    icon: <span>🌐</span>,
    shortDescription: "Applies instantly",
    longDescription: "Change is reflected on website reload.",
  },
  {
    icon: <span>⌚</span>,
    shortDescription: "Applies with cache",
    longDescription:
      "Change will reflect when cache expires, which is usually less than 15 minutes.",
  },
];

const scenarios = [
  {
    title: "Disable filtering and display the maintenance message for a platform:",
    settings: [
      { label: "Enabled", value: "On" },
      { label: "Membership Required", value: "(Don’t Change)" },
      { label: "Movies Active", value: "Off" },
      { label: "Shows Active", value: "Off" },
      { label: "Searchable", value: "On" },
      { label: "Web Banner", value: "On" },
      { label: "Web Search", value: "Off" },
      { label: "Web Shelves", value: "Off" },
      { label: "Maintenance Message", value: "Provide the desired message" },
    ],
  },
  {
    title: "New platform (Coming soon) (No released assets):",
    settings: [
      { label: "Enabled", value: "Off" },
      { label: "Movies Active", value: "On" },
      { label: "Searchable", value: "On" },
    ],
  },
];

const PlatformKey: React.FC = () => {
  const [open, setOpen] = useState(false);

  const renderValue = (value: string) => {
    return (
      <code
        style={{
          color: value.toLowerCase() === "on" ? "green" : "inherit",
          fontWeight: value.toLowerCase() === "on" ? "bold" : "inherit",
        }}
      >
        {value}
      </code>
    );
  };

  return (
    <KeyPoppupWrapper>
      <CustomTooltip title="Click Here For Common Scenarios">
        <IconButton onClick={() => setOpen(true)}>
          <InfoOutlined />
        </IconButton>
      </CustomTooltip>

      <GridWrapper container spacing={2}>
        {headerKeys.map((key, index) => (
          <Grid item key={index}>
            <CustomTooltip title={key.longDescription}>
              <Typography variant="body2">
                {key.icon} {key.shortDescription}
              </Typography>
            </CustomTooltip>
          </Grid>
        ))}
      </GridWrapper>

      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="platform-key-dialog">
        <DialogTitle id="platform-key-dialog">Additional Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Common Scenarios</Typography>
            <ul>
              {scenarios.map((scenario, index) => (
                <li key={index}>
                  <strong>{scenario.title}</strong>
                  <ul>
                    {scenario.settings.map((setting, idx) => (
                      <li key={idx}>
                        {setting.label}: {renderValue(setting.value)}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </KeyPoppupWrapper>
  );
};

export default PlatformKey;