import styled from "styled-components/macro";
import { Colors } from "../theme";
import { Select } from "@mui/material";

export const DashboardWrapper = styled.div`
  display: flex;
  align-items: left;
  min-height: 100vh;
  margin: auto;
  margin-top: 80px;
  flex-direction: column;
  max-width: 95vw;
  .MuiPopover-paper {
    background: #15202b;
  }
`;

export const Header = styled.h1`
  margin-top: 0;
  font-size: 40px;
  color: #fff;
  text-align: center;
`;

export const SelectDropdown = styled(Select)`
  .MuiSelect-select {
    color: white;
    font-size: clamp(20px, 4vw, 24px);
    padding-top: 10px;
  }
  width: clamp(200px, 70vw, 300px);
  &:after {
    display: none;
  }
  &:before {
    display: none;
  }
  .MuiSelect-select:focus {
    background: transparent;
  }
  svg {
    padding-bottom: 5px;
    color: ${Colors.colorBlue};
  }
`;

export const SelectToggleWrapper = styled.div`
 overflow-x: auto;
 position: fixed;
 height: 50px;
  @media screen and (min-width: 900px) {
    position: fixed;
    top: 10px;
    right: 40%;
    z-index: 1200;
  }
`;
export const TableActionButton = styled.div`
  top: 50px;
  color: white;
  z-index: 10;
  padding-right: 25px;
  span {
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      color: gray;
    }
  }
`;
