import "./App.css";
import { AppRouter } from "./AppRouter";
import AppSnackBar from "./features/components/SnackBar";
import TagManager from "react-gtm-module";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAppInit } from "./hooks/useAppInit";
import 'search-insights';
import { useEffect } from "react";


const tagManagerArgs = {
  gtmId: "GTM-M53MN2J",
};


TagManager.initialize(tagManagerArgs);

function App() {
  useAppInit()

   useEffect(() => {
   if (window.aa)
    window.aa('init', {
      appId: process.env.REACT_APP_ALGOLIA_APP!,
      apiKey: process.env.REACT_APP_ALGOLIA_KEY!
    });
   }, [])


  return (
    <>
      <AppSnackBar />
      <AppRouter />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
