import React, { useEffect, useRef, useState } from "react";
import { NoUrl } from "./PosterImage.styles";
import { ImageWrapper, ProviderDown } from "./LazyImage.styles";
import { SearchImageWrapper } from "./SearchImage.styles";
import PosterImage from "./PosterImage";
import { useHover } from "../../hooks/useHover";
import { filteringDownMessage, filteringDownProvider } from "../../admin/utils";
import { useFeature } from "../../hooks/useFeature";
import { queryClient } from "../../lib/react-query";
import { Platform } from "../adminFeatures/tables/tableTypes";
import { FetchUserQueryUtilEnum } from "../user/userTypes";
import { userDeviceSiganl, userLoadingSignal } from "../user/userSignals";
import { Asset } from "../adminFeatures/assets/assetTypes";
import { clickedPosterSignal } from "./shelfComponents/rowUtil";

interface LazyImageProps {
  value: number;
  asset: Asset;
  expanded: boolean;
  isSearch?: boolean;
  focused: boolean;
  open?: boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  focusable: boolean;
  index: string;
  isDragging?: boolean;
}

const LazyImage = React.memo<LazyImageProps>((props) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const {
    asset,
    value,
    expanded,
    isSearch,
    focused,
    open,
    focusable,
    index,
    isDragging
  } = props;
  const [loading, setLoading] = useState(true);
  const filteringDown = queryClient.getQueryData<Platform[]>([
    FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS,
  ]);
  const message = filteringDownMessage(filteringDown, Number(asset.platform_id));
  const isLoading = loading || userLoadingSignal.value;
  const providerDownRef = useRef<HTMLDivElement | null>(null);
  const { assetClick } = useFeature();
  const isMobile = userDeviceSiganl.value === "mobile";


  useEffect(() => {
    if (!asset?.img_url) {
      setLoading(false);
    }
  }, [asset.img_url]);

  const handlePosterClick = (e: React.MouseEvent | React.TouchEvent) => {
    if (isDragging) {
      return;
    }

    const platformStatus = filteringDownProvider(
      filteringDown,
      Number(asset.platform_id)
    );

    if (loading) return;

    if (platformStatus?.web_shelves === false) {
      clickedPosterSignal.value = index;
      e.stopPropagation();
    } else {
      assetClick(isLoading, value, asset);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        providerDownRef.current &&
        !providerDownRef.current.contains(event.target)
      ) {
        clickedPosterSignal.value = null;
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [providerDownRef, index]);


  return (
    <>
      <ProviderDown
        hidden={clickedPosterSignal.value !== index}
        ref={providerDownRef}
        open={clickedPosterSignal.value === index}
        onClose={() => clickedPosterSignal.value = (null)}
      >
        {message}
      </ProviderDown>
      {!isSearch ? (
          <ImageWrapper
            isMobile={isMobile}
            ref={hoverRef}
            focused={focused.toString()}
            focusable={focusable}
            loading={loading.toString()}
            expanded={expanded}
            onClick={handlePosterClick}
            open={open}
          >
          {asset.img_url ? (
            <PosterImage
              setLoading={setLoading}
              asset={asset}
              isHovered={isHovered || focused}
            />
          ) : (
            <NoUrl>
              {/* {film.expand ? <div>{film.display_name}</div> : film.display_name}
              {film.expand && !open ? (
                <span>{film.expand}</span>
              ) : (
                <span>{film.close}</span>
              )} */}
            </NoUrl>
          )}
        </ImageWrapper>
      ) : (
        <SearchImageWrapper
          asset={asset}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
});

export default LazyImage;
