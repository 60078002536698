import styled from "styled-components/macro";
import { TextField, Toolbar, Typography } from "@mui/material";

export const TableToolBar = styled(Toolbar)`
  svg {
    fill: white;
  }
  z-index: 10;
  flex-wrap: wrap;
  width: 96vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end !important;
  padding-left: 0 !important;
  padding-bottom: 20px;
  @media screen and (max-width: 900px) {
    position: fixed !important;
    top: 120px;
    left: 10px;
  }
`;
export const SearchBox = styled(TextField)`
  background: white;
  border-radius: 5px;
  padding: 0 5px;
  align-self: start;
  max-width: 400px;
  width: clamp(200px, 70vw, 400px);
  div {
    &:after {
      border-bottom: none;
    }
    &:before {
      border-bottom: none;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  input {
    padding: 5px;
  }
`;

export const SearchClearWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 500px;
  font-size: clamp(12px, 2vw, 16px);
`;

export const FilterGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-items: flex-end;
  color: white;
  margin-top: 5px;
  #classification_version_id,
  #episode_classification_version_id {
    order: 1;
  }
  #searchable,
  #series_searchable {
    order: 2;
  }
  #filter_released,
  #episode_filter_released {
    order: 3;
  }
  #img_URL,
  #series_img_URL {
    order: 4;
  }
  #imdb_id,
  #episode_imdb_id {
    order: 5;
  }
  .MuiTypography-root {
    font-size: clamp(12px, 2vw, 16px) !important;
  }
`;

export const KeySearchWarpper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ToggleHide = styled(Typography)`
  margin-right: 20px !important;
  border: 1px solid rgb(194, 112, 192);
  border-radius: 8px;
  color: rgb(194, 112, 192);
  font-size: clamp(12px, 2vw, 16px);
  padding: 5px 10px;
  :hover {
    cursor: pointer;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
`;
