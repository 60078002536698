import { PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import type { TableInstance } from 'react-table';
import { AdminTableSelection } from '../../features/adminFeatures/tables/tableTypes';
import {
  ClearFilter,
  GroupByOutlined,
  GroupCheckBox,
} from './CustomTable.styles';
import {
  SearchBox,
  TableToolBar,
  SearchClearWrapper,
  FilterGrid,
  KeySearchWarpper,
  ToggleHide,
  GridWrapper,
} from './TableToolbar.styles';
import ColorKey from './ColorKey';
import PlatformKey from './PlatformKey';
import { selectedTable } from '../../features/adminFeatures/useAdminData';
import { showHideButton, toggleHiddenColumns } from './useSharedTableData';
import { signal } from '@preact/signals-react';
import { TableActionButton } from '../AdminDashboard.styles';


export const shelfModalOpenSignal = signal(false)
export const addShelfModalOpenSignal = signal(false)
export const wrapTextSignal = signal(false)
export const movieFilterSignal = signal<string | null>(null);

interface TableToolbarProps {
  instance: TableInstance<any>;
  setGlobalFilter: Function;
  groupBy: any;
  setGroupBy: Function;
  toolBarRef: React.RefObject<HTMLDivElement>;
}


export function TableToolbar({
  instance,
  setGlobalFilter,
  groupBy,
  setGroupBy,
  toolBarRef
}: PropsWithChildren<TableToolbarProps>): ReactElement | null {
  const { allColumns, setHiddenColumns } = instance;
    // Columns to toggle
    const columnsToToggle = useMemo(() => toggleHiddenColumns, []);
    const [isToggled, setIsToggled] = useState(false);

    // Toggle visibility function
    const toggleColumnsVisibility = useCallback(() => {
      setHiddenColumns((currentHidden: string[]) => {
        const areColumnsHidden = columnsToToggle.every(column => currentHidden.includes(column));
        // Update the toggle state
        setIsToggled(!areColumnsHidden);
  
        return areColumnsHidden 
          ? currentHidden.filter((column: string) => !columnsToToggle.includes(column))
          : Array.from(new Set([...currentHidden, ...columnsToToggle]));
      });
    }, [columnsToToggle, setHiddenColumns]);
 
  const handleFilterInputChange = (e: { currentTarget: { value: any } }) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const handleClearFilter = () => {
    const input = document.getElementById('globalFilter') as HTMLInputElement;
    if (input) {
      input.value = '';
      setGlobalFilter('');
    }
  };

  useEffect(() =>
  {
    const input = document.getElementById('globalFilter') as HTMLInputElement;
    if (movieFilterSignal.value) {
      input.value = movieFilterSignal.value;
      setGlobalFilter(movieFilterSignal.value);
    }
    input.focus()
  },[])

  // toolbar with add, edit, delete, filter/search column select.
  return (
    <TableToolBar ref={toolBarRef}>
      <KeySearchWarpper>
      <SearchClearWrapper>
        {selectedTable.value === AdminTableSelection.SHOW_ASSET && (
          <GroupCheckBox
            onClick={() =>
              groupBy.length ? setGroupBy([]) : setGroupBy(['series_name'])
            }
            icon={<GroupByOutlined isToggled={groupBy !== null} />}
            checkedIcon={<GroupByOutlined />}
          />
        )}
        <SearchBox
          autoComplete="off"
          id='globalFilter'
          placeholder='Filter'
          onChange={handleFilterInputChange}
        />
        <ClearFilter onClick={() => handleClearFilter()}>
          Clear Fliter
        </ClearFilter>
      </SearchClearWrapper>
            <ColorKey/>
        
      </KeySearchWarpper>
   
      <GridWrapper>
     
      <FilterGrid>
      <ToggleHide  onClick={() => wrapTextSignal.value = (!wrapTextSignal.value)} >{wrapTextSignal.value ? 'No Wrap' : 'Wrap'}</ToggleHide>
      {showHideButton.includes(selectedTable.value) && <ToggleHide  onClick={toggleColumnsVisibility} >{isToggled ? 'Show Columns' : 'Hide Columns'}</ToggleHide>}
      {selectedTable.value === AdminTableSelection.SHELVES && (
        <TableActionButton>
          <span onClick={() => shelfModalOpenSignal.value = (true)}>
            Change Shelves Priority
          </span>
          <span onClick={() => addShelfModalOpenSignal.value = (true)}>Add Shelf</span>
        </TableActionButton>
      )}
        {allColumns
          .filter((it) => it.canFilter)
          .map((column) => {
            return <div id={column.id} key={column.id}>{column.render('Filter')}</div>;
          })}
      {selectedTable.value === AdminTableSelection.PLATFORM_CONTROLS ? <PlatformKey/> : <></>}
      </FilterGrid>
      </GridWrapper>
    
    </TableToolBar>
  );
}