import styled from "styled-components/macro";

export const KeyWarpper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #15202b !important;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  height: 25px;
  margin-top: 10px;
  width: clamp(200px, 80vw, 500px);
`;
export const KeysWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    margin: 0 10px;
    font-size: clamp(10px, 2vw, 12px);

  }
`;
export const ColorPallet = styled.div`
  width: clamp(15px, 5vw, 30px);
  height: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;
