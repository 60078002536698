import React, { useEffect, useState } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import {
  Provider,
  SlidingUnderline,
  TabHolder,
} from "./ProviderTabComponent.styles";
import { filteringDownProvider } from "../../admin/utils";
import { queryClient } from "../../lib/react-query";
import { Platform } from "../adminFeatures/tables/tableTypes";
import { FetchUserQueryUtilEnum } from "../user/userTypes";
import { tabProviderMap } from "../../app/app.types";
import { flixProviderSignal } from "../flix/useFlix";

import MobileProviderMenu from "./MobileProviderMenu";
import { userLoggedInSignal } from "../user/userSignals";

interface ProviderTabComponentProps {
  loggedIn: boolean;
  providerTab: number;
  mounted: boolean;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProviderLinkProps {
  loggedIn: boolean;
  label: string;
  index: number;
  handleTabChange: (index: number) => void;
  isInitiallyFocused: boolean;
  selectedTab: number;
  onMeasure: (offsetLeft: number, offsetWidth: number) => void;
}

const ProviderLinks: React.FC<ProviderLinkProps> = React.memo(
  ({
    loggedIn,
    label,
    index,
    handleTabChange,
    isInitiallyFocused,
    selectedTab,
    onMeasure,
  }) => {
    const { ref, focusKey, focusSelf } = useFocusable({
      onFocus: () => handleTabChange(index),
      focusKey: `provider-tab-${index}`,
    });

    useEffect(() => {
      if (isInitiallyFocused) {
        focusSelf(); // Focus the Amazon tab initially
      }
    }, [isInitiallyFocused, focusSelf]);

    const isSelected = index === selectedTab;

    // Report tab dimensions to the parent after rendering
    useEffect(() => {
      if (isSelected && ref.current) {
        requestAnimationFrame(() => {
          if (ref.current) {
            // Ensure ref.current is still valid
            const { offsetLeft, offsetWidth } = ref.current;
            onMeasure(offsetLeft, offsetWidth);
          }
        });
      }
    }, [isSelected, onMeasure]);

    return (
      <Provider
        ref={ref}
        data-focuskey={focusKey}
        selectionFollowsFocus={true}
        disableRipple
        label={label}
        isSearch={label === "Search"}
        focused={isSelected ? "focused" : ""}
        onClick={() => {
          handleTabChange(index); // Trigger tab change on click
          focusSelf(); // Programmatically set focus on click
        }}
        loggedin={loggedIn.toString()}
      />
    );
  }
);

const ProviderTabComponent: React.FC<ProviderTabComponentProps> = ({
  loggedIn,
  providerTab,
  mounted,
  setMounted,
}) => {
  const initialTabIndex = 0;
  const tabs = ["Amazon", "Apple TV+", "Disney+", "Max", "Netflix", "Search"];
  const safeProviderTab = providerTab ?? 0; // Fallback to 0 if providerTab is undefined
  const [selectedTab, setSelectedTab] = useState<number>(safeProviderTab);
  const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });

  const filteringDown = queryClient.getQueryData<Platform[]>([
    FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS,
  ]);

  const { ref, focusKey, focusSelf } = useFocusable({
    focusable: true,
    trackChildren: true,
    autoRestoreFocus: true,
    focusKey: "Tab-Menu",
  });

  const [isMobileView, setIsMobileView] = useState(() =>
    userLoggedInSignal.value
      ? window.innerWidth < 600
      : window.innerWidth < 1150
  );

  const handleResize = () => {
    setIsMobileView(
      userLoggedInSignal.value
        ? window.innerWidth < 600
        : window.innerWidth < 1150
    );
  };

  const handleMeasure = (left: number, width: number) => {
    setIndicatorStyle({ left, width }); // Update the sliding underline's position and size
  };

  const handleTabChange = (index: number) => {
    flixProviderSignal.value = tabProviderMap[index];
    setSelectedTab(index);
  };

  useEffect(() => {
    // Set up resize listener
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Ensure focus and selection default to Amazon on the first render
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      setSelectedTab(initialTabIndex);
      flixProviderSignal.value = tabProviderMap[initialTabIndex];
      focusSelf();
    }
  }, [mounted, setMounted, focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      {isMobileView ? (
        <MobileProviderMenu providerTab={initialTabIndex} />
      ) : (
        <TabHolder
          id="tab-holder"
          messaging={filteringDownProvider(
            filteringDown,
            selectedTab
          )?.web_banner?.toString()}
          loggedIn={loggedIn}
          ref={ref}
          value={selectedTab}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          {tabs.map((name, index) => (
            <ProviderLinks
              selectedTab={safeProviderTab}
              key={index}
              loggedIn={loggedIn}
              label={name}
              index={index}
              onMeasure={handleMeasure}
              handleTabChange={handleTabChange}
              isInitiallyFocused={index === initialTabIndex && !mounted}
            />
          ))}
          <SlidingUnderline
            selectedTab={selectedTab}
            totalTabs={6}
            style={{ left: indicatorStyle.left, width: indicatorStyle.width }}
          />
        </TabHolder>
      )}
    </FocusContext.Provider>
  );
};
export default React.memo(ProviderTabComponent);
